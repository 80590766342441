@import '~golden-layout/src/css/goldenlayout-dark-theme';

::-webkit-scrollbar {
    background: lighten(#1e1e1e, 10%);
}

::-webkit-scrollbar-thumb {
    background: lighten(#1e1e1e, 20%);
}

a {
    color: #45bbe0 !important;

    &.navbar-brand {
        img.logo {
            &.inverse {
                display: block !important;
            }

            &.normal {
                display: none !important;
            }
        }
    }
}

body {
    background-color: #333 !important;
}

input,
textarea {
    color: #eee !important;
    background-color: #474747;
    border: 0 !important;

    &:focus {
        color: #eee !important;
        background-color: #474747;
        border: 0 !important;
    }

    &.permalink {
        color: #eee !important;
        background-color: #474747;
        opacity: 1;
    }
}

kbd {
    border-color: #818181 !important;
}

pre {
    color: #f2f2f2 !important;

    .content {
        background-color: #1e1e1e !important;

        .compiling {
            background-color: #101010 !important;
        }
    }
}

select {
    color: #000 !important;
}

textarea.form-control {
    color: #eee !important;
    background-color: #474747;
    border: 0 !important;
}

.argmenuitem {
    max-width: 250px;

    span {
        &.argtitle {
            font-weight: bold;
            display: block;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        &.argdescription {
            max-height: 150px;
            word-wrap: break-word;
            overflow-wrap: break-word;
            font-style: italic;
            font-size: smaller;
            display: block;
            white-space: normal;
            text-overflow: ellipsis;
            overflow-y: hidden;
        }
    }
}

.bg-light {
    background-color: #333 !important;
}

.bottom-bar {
    color: #aaa !important;
    background-color: #333 !important;

    &.bg-light {
        border-top: 1px solid #202122;
    }
}

.btn-light {
    color: #e2e2e2 !important;
    background-color: #333 !important;
    border-color: #333 !important;

    &.active {
        background-color: #235765 !important;
        color: #fff !important;

        &:hover {
            background-color: #998899 !important;
        }
    }

    &:hover {
        background-color: #23272b !important;
    }

    &:disabled,
    &:disabled:hover {
        background-color: #575757 !important;
        color: #fff !important;
    }
}

.card {
    background-color: #1e1e1e !important;
    color: #f2f2f2 !important;
}

.commit-entry {
    &:nth-child(odd) {
        background-color: #222222 !important;
    }

    &:nth-child(even) {
        background-color: #424242 !important;
    }
}

.community-advert {
    color: white;
    background: #474747 !important;
    border-color: #006400;

    button {
        opacity: 1;
        color: #006400;
        text-shadow: none;
    }
}

.conformance-wrapper {
    background-color: #1e1e1e !important;

    .compiler-list .form-row {
        border-bottom: 1px solid #3e3e3e;
    }
}

.copy-link-btn:hover {
    background-color: #12161a !important;
}

.cppi-logo-sec {
    fill: #e2e2e2 !important;
}

.currentCursorPosition {
    color: #17a2b8;
    background-color: rgba(49, 54, 60, 0.85);
}

.custom-select {
    background-color: #76a1c8 !important;
}

.dropdown-item {
    color: #eee !important;
    background-color: #303030;

    &:hover {
        color: #fff !important;
        background-color: #23272b !important;
    }
}

.dropdown-menu {
    color: #f2f2f2 !important;
    background-color: #303030 !important;
}

.err-count {
    color: orange !important;
}

.execution-stdout {
    color: white !important;
}

.fa,
.fas {
    color: white;
}

.float-link {
    color: #eee !important;
    background-color: rgba(128, 128, 128, 0.5) !important;

    &:hover {
        background-color: rgba(128, 128, 160, 0.5) !important;
    }
}

.flow-decoration {
    background-color: #b4131f !important;
    color: white !important;
    font-weight: bold;
}

.flow-highlight {
    background-color: rgba(180, 19, 31, 0.4) !important;
}

.form-control:disabled {
    background-color: darken(#474747, 10%);
}

.graph-container {
    background: #222222;
    .cfg-info {
        color: #aaa;
    }
    .graph .block-container .block {
        background: black;
        border: 1px solid white;
        color: white;
    }
}

.input-group-text {
    background-color: #a1a1a1 !important;
    border-color: #818181 !important;
}

.logo-pri {
    fill: #67c52a !important;
}

.logo-sec {
    fill: #999999 !important;
}

#library-selection {
    .libs-selected-col button {
        color: white;
    }

    .libs-favorites-col button {
        color: white;
    }

    .libs-results-col .lib-fav-button {
        color: white;
    }
}

.lib-item {
    background-color: #333 !important;
}

.linked-code-decoration {
    background: #555657 !important;
}

.linked-code-decoration-inline {
    background: #444444;
}

.linked-code-decoration-line {
    background: rgb(26, 53, 76) !important;
}

.linked-code-decoration-margin {
    background: #433b4b !important;
}

.linked-compiler-output-line {
    color: #007bfd !important;
}

.lm_controls {
    .lm_maximise {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 9'%3E%3Cpath fill='%23fff' d='M0 4.5V0h9v9H0zM8 5V2H1v6h7z'/%3E%3C/svg%3E") !important;
        background-size: 9px !important;
    }

    .lm_close {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 9'%3E%3Cpath fill='%23fff' d='M.45713 8.5429l-.44426-.44438 3.5955-3.5956L.00697.90152l.8944-.89463 3.6016 3.6015 3.6014-3.6015.88867.88867-3.6014 3.6015 3.6014 3.6015-.89449.89449-3.6015-3.6014-3.5957 3.5956z'/%3E%3C/svg%3E") !important;
        background-size: 9px !important;
    }
}

.lm_header {
    background-color: #121212 !important;

    .lm_tab {
        /*
         * replace low res golden-layout icons with svg recreations to improve high DPI displays
         * not all icons in golden-layout are used, so we don't replace all of them
         */
        .lm_close_tab {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 9'%3E%3Cpath fill='%23fff' d='M.45713 8.5429l-.44426-.44438 3.5955-3.5956L.00697.90152l.8944-.89463 3.6016 3.6015 3.6014-3.6015.88867.88867-3.6014 3.6015 3.6014 3.6015-.89449.89449-3.6015-3.6014-3.5957 3.5956z'/%3E%3C/svg%3E") !important;
            background-size: 9px !important;
        }

        .lm_modify_tab_title {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' class='bi bi-pencil' viewBox='0 0 16 16'%3E%3Cpath d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z'/%3E%3C/svg%3E") !important;
            background-size: 9px !important;
            background-repeat: no-repeat;
            background-position: center center;
            width: 11px;
            height: 11px;
            position: absolute;
            top: 4px;
            right: 23px;
        }
    }
}

.lm_maximised .lm_controls .lm_maximise {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 9'%3E%3Cpath fill='%23fff' d='M1.0096 8.0019v-.99809h6.9807v1.9962H1.0096z'/%3E%3C/svg%3E") !important;
    background-size: 9px !important;
}

.modal-body {
    background-color: #333 !important;
    color: #eee !important;
}

.modal-content {
    .well {
        background-color: #222 !important;
        color: #eee !important;
    }

    .card-body {
        background-color: #141414 !important;
    }
}

.modal-footer {
    background-color: #333 !important;
}

.modal-header {
    color: #eee !important;
    background-color: #333 !important;

    .close {
        color: #fff;
    }
}

.nav.nav-tabs {
    li {
        a {
            color: #eee !important;
            background-color: #666 !important;
        }
    }

    li:not(.active) {
        a {
            border-bottom: 1px solid #fff !important;
        }
    }

    li.active {
        a {
            background-color: #666 !important;
        }
    }
}

.navbar-light {
    background-color: #333 !important;

    .navbar-nav li {
        &:hover {
            color: #fff !important;
            background-color: #23272b !important;
        }

        a {
            color: #fff !important;

            &:hover {
                color: #fff !important;
                background-color: #23272b !important;
            }
        }
    }

    a.nav-link {
        border: rgb(140, 140, 140) 1px solid;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }

    .navbar-toggler {
        color: rgba(255, 255, 255, 0.5);
        border-color: rgba(255, 255, 255, 0.1);
    }
}

.new-cookie-msg {
    color: white;
}

.notification {
    background-color: gray !important;
}

.notification-error {
    background-color: #aa3333 !important;
    color: #fff;

    .close {
        color: #fff;
    }
}

.notification-info {
    background-color: #676767 !important;
    color: #f2f2f2;
}

.notification-off {
    background-color: #222 !important;
    color: #fff;

    .close {
        color: #fff;
    }
}

.notification-on {
    background-color: #33aa33 !important;
    color: black;
}

.opt-decoration {
    &.analysis,
    &.mixed {
        background: #fdfd96 !important;
    }

    &.passed {
        background: #77dd77 !important;
    }

    &.missed {
        background: #ff6961 !important;
    }
}

.popover,
.popover-content,
.libs-container,
.lib-list,
.popover-body,
.popover-header {
    background-color: #151515 !important;
    color: #f2f2f2 !important;
}

.popular-arguments-btn {
    border-color: #474747 !important;
}

.popover .arrow::after,
.popover .arrow::before {
    border-left-color: #151515 !important;
    border-right-color: #151515 !important;
}

.popover {
    border-color: #464545 !important;
}

.prepend-options {
    border-color: #444 !important;
    background-color: #353535 !important;
}

.picker-popout-button {
    border: none !important;
    background: #444444 !important;
    &:hover {
        background: darken(#444444, 5%) !important;
    }
}

.qb-logo-pri {
    fill: #e2e2e2 !important;
}

.qb-logo-sec {
    fill: #333 !important;
}

.rainbow-decoration {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
        60deg,
        #ed4242 0,
        #ffaa44 20%,
        #fff344 40%,
        #43a35b 60%,
        #447cff 80%,
        #9c44a9 100%
    );
}

#renamepanemodal .modal-body {
    min-height: 100px;
}

.share-disabled {
    color: #333 !important;
}

.share-reddit {
    background-color: #ff4500 !important;
    color: white !important;
}

.share-twitter {
    background-color: #1da1f2 !important;
    color: white !important;
}

#simplecook {
    background-color: #474747 !important;
}

.text-count {
    color: green !important;
}

.top-bar.btn-toolbar.bg-light {
    border-bottom: 1px solid #202122;
}

.tree {
    ul,
    li {
        background-color: #222222 !important;
        color: white;
    }

    li.tree-editor-file {
        background-color: #303030 !important;
        color: white;

        &:hover {
            background-color: #333 !important;
        }
    }
}

.ts-dropdown {
    color: #f2f2f2 !important;
    background-color: #303030 !important;
    margin: 0 !important;

    .option:hover {
        background-color: #8d8d8e;
    }

    .active {
        background-color: #68688e !important;
    }

    .selected {
        background-color: #21525f !important;
        color: #dddddd !important;
    }

    .optgroup-header {
        background-color: #555 !important;
        color: #eee !important;
        text-shadow: none !important;
    }
}

.ts-wrapper.single {
    .ts-control {
        border: none;
        color: #f2f2f2 !important;
        background: #444 !important;
        text-shadow: none !important;

        &:after {
            border-color: #728690 transparent transparent transparent;
        }

        &.disabled {
            color: #f2f2f2 !important;
            background-color: #383838 !important;
        }
    }
}

#compiler-picker-modal {
    .architecture,
    .compiler-type {
        background: #444;
        &.active {
            background: #007bff;
        }
    }

    .compilers-col .group-wrapper .group {
        .label {
            background: #555555;
        }
        .compiler {
            &:hover {
                background: #8d8d8e;
            }
            &.selected {
                background: #21525f;
            }
        }
    }
}

#alert {
    &.error-alert {
        .modal-content {
            border: 4px solid #f43636;
        }
    }
}

.compiler-arg-warning-icon {
    color: #ffbf3f !important;
}

.compiler-arg-warning {
    border-color: #ffbf3f;
    &:before {
        border-color: #ffbf3f;
        background: #ffbf3f;
    }
    &.info {
        border-color: #3f92ff;
        &:before {
            border-color: #3f92ff;
            background: #3f92ff;
            content: '\f05a';
            color: rgb(20, 21, 22);
        }
    }
}
